import styled, { css } from 'styled-components'

export type DatePickerTriggerProps = {
  $hasError?: boolean
  $hasValue?: boolean
}

export const DatePickerTrigger = styled.button<DatePickerTriggerProps>`
  min-width: ${({ theme: { layout } }) => layout.spacing(15)};
  min-height: ${({ theme: { layout } }) => layout.spacing(7)};
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 10px;
  padding: ${({ theme }) => `${theme.layout.spacing(1)} ${theme.layout.spacing(2)}`};
  background: ${({ theme }) => theme.colors.backgroundContent};
  color: ${({ theme }) => theme.colors.text};
  font-size: ${({ theme }) => theme.fontSize.default};
  line-height: 2;
  cursor: pointer;
  ${({ $hasError }) =>
    $hasError &&
    css`
      border-color: ${({ theme }) => theme.colors.error};
    `}
  ${({ $hasValue }) =>
    !$hasValue &&
    css`
      color: ${({ theme }) => theme.colors.disabled};
    `}

  &:disabled {
    border-color: ${({ theme }) => theme.colors.disabled};
    color: ${({ theme }) => theme.colors.disabled};
    cursor: default;
  }
`

export const DatePickerControlsWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const DatePickerTriggerSeparator = styled.span`
  padding: ${({ theme }) => `${theme.layout.spacing(1)} ${theme.layout.spacing(2)}`};
  color: ${({ theme }) => theme.colors.disabled};
`
export const DatePickerCalendarWrapper = styled.div`
  z-index: 2000;
  box-shadow: 0 4px 4px 0 #0003;
  margin-top: 13px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 10px;
  background: ${({ theme }) => theme.colors.backgroundContent};

  .rdp {
    --rdp-cell-size: 32px;
    --rdp-accent-color: ${({ theme }) => theme.colors.active};
    --rdp-accent-color-dark: ${({ theme }) => theme.colors.backgroundActive};
    --rdp-outline: none;
    --rdp-outline-selected: none;
    margin: 0;
  }

  .rdp-month {
    margin: 0 !important;
    padding: ${({
      theme: {
        layout: { spacing },
      },
    }) => `${spacing(1.5)} ${spacing(4)} ${spacing(1.5)} ${spacing(3)}`};

    &:not(:last-of-type) {
      border-right: 1px solid ${({ theme }) => theme.colors.border};
    }
  }

  .rdp-multiple_months .rdp-caption_end .rdp-nav {
    right: -20px;
  }

  .rdp-multiple_months .rdp-caption_start .rdp-nav {
    left: -12px;
  }

  .rdp-caption_label {
    font-size: ${({ theme }) => theme.fontSize.small};
    line-height: 21px;
  }

  .rdp-nav_button svg {
    width: 10px;
    height: 10px;
  }

  .rdp-caption {
    margin-bottom: ${({ theme }) => theme.layout.spacing(3)};
  }

  .rdp-head_cell {
    font-weight: 400;
    font-size: ${({ theme }) => theme.fontSize.small};
    line-height: 21px;
  }

  .rdp-cell {
    padding: 4px 0;
  }

  .rdp-weeknumber,
  .rdp-day {
    font-size: ${({ theme }) => theme.fontSize.small};
    line-height: 32px;
  }

  .rdp-button:hover:not([disabled]) {
    border-color: transparent !important;
    border-radius: 50% !important;
    background-color: ${({ theme }) => theme.colors.active};
    color: ${({ theme }) => theme.colors.backgroundContent};
  }

  .rdp-day_today:not(.rdp-day_outside) {
    border: 1px solid ${({ theme }) => theme.colors.text};
    font-weight: 400;
  }

  .rdp-day_today.rdp-day_selected {
    border: none;
  }

  .rdp-day_range_middle {
    background-color: ${({ theme }) => theme.colors.backgroundActive};
    color: ${({ theme }) => theme.colors.text};
  }

  .rdp:not([dir='rtl']) .rdp-day_range_start:not(.rdp-day_range_end),
  .rdp:not([dir='rtl']) .rdp-day_range_end:not(.rdp-day_range_start) {
    position: relative;
    overflow: unset;
    border-radius: 50% !important;

    &::before {
      content: '';
      position: absolute;
      z-index: -1;
      display: block;
      width: 18px;
      height: 32px;
      border-radius: 0;
      background: ${({ theme }) => theme.colors.backgroundActive};
    }
  }

  .rdp:not([dir='rtl']) .rdp-day_range_start:not(.rdp-day_range_end)::before {
    right: -2px;
  }

  .rdp:not([dir='rtl']) .rdp-day_range_end:not(.rdp-day_range_start)::before {
    left: -2px;
  }

  .rdp-row .rdp-cell:last-child .rdp-day_range_middle {
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
  }

  .rdp-row .rdp-cell:first-child .rdp-day_range_middle {
    border-bottom-left-radius: 50%;
    border-top-left-radius: 50%;
  }
`
