import styled from 'styled-components'

import { H3 } from '@/lib/core/components/Text'
import { Wrapper as InfoboxWrapper } from '@/lib/core/components/Infobox/Infobox.styles'

export const Container = styled.div`
  overflow: hidden;
  overflow-y: auto;
  max-width: 100%;
  max-height: 100%;
  border-radius: ${({ theme }) => theme.layout.borderRadius};
  padding: ${({
    theme: {
      layout: { spacing },
    },
  }) => `${spacing(10)} ${spacing(7)}`};
  background: #fff;
`

export const Heading = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.layout.spacing(3)};
  min-height: ${({ theme }) => theme.layout.spacing(22)};
  padding: ${({ theme }) => theme.layout.spacing(1)};

  ${H3} {
    margin: 0;
    font-weight: 700;
  }
`

export const Content = styled.div`
  overflow: hidden;
  flex: 1;
  min-height: ${({ theme }) => theme.layout.spacing(12)};

  ${InfoboxWrapper} {
    min-width: 44vw;
    max-width: 100%;
    min-height: ${({ theme }) => theme.layout.spacing(15)};
    font-size: 1.125rem;
  }
`

export type FooterProps = {
  $actionsAlignItems?: string
  $actionsJustifyContent?: string
}

export const Footer = styled.footer<FooterProps>`
  display: flex;
  justify-content: ${({ $actionsJustifyContent }) => $actionsJustifyContent};
  align-items: ${({ $actionsAlignItems }) => $actionsAlignItems};
  gap: ${({ theme }) => theme.layout.spacing(3)};
  min-height: ${({ theme }) => theme.layout.spacing(11)};
`
