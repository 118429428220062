import { CheckmarkComponent } from './Checkmark'
import { StyledCheckmark } from './Checkmark.styles'

export type { CheckmakrElementProps } from './Checkmark'

export const Checkmark = Object.assign(CheckmarkComponent, {
  Styled: {
    StyledCheckmark,
  },
})
