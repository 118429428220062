import styled from 'styled-components'

import { BASE_CHECKBOX_SIZE } from '@/lib/core/components/Checkbox/Checkbox.styles'

type StyledCheckmarkProps = {
  isActive: boolean
}

export const StyledCheckmark = styled.span<StyledCheckmarkProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: ${BASE_CHECKBOX_SIZE};
  height: ${BASE_CHECKBOX_SIZE};
  border: ${({ theme: { borderBase } }) => borderBase};
  border-radius: 4px;
  background: #fff;
  user-select: none;

  svg {
    position: absolute;
    top: 7px;
    left: 5px;
    opacity: ${({ isActive }) => (isActive ? 1 : 0)};
    width: 13px;
    height: auto;
  }
`
