import { defineMessages } from 'react-intl'

export default defineMessages({
  back: {
    defaultMessage: 'Back',
    description: 'modals | back button text',
  },
  cancel: {
    defaultMessage: 'Cancel',
    description: 'modals | cancel button text',
  },
  close: {
    defaultMessage: 'Close',
    description: 'modals | Close button text',
  },
  confirm: {
    defaultMessage: 'Confirm',
    description: 'modals | confirm button text',
  },
  continue: {
    defaultMessage: 'Continue',
    description: 'modals | continue button text',
  },
  noCancel: {
    defaultMessage: 'Do Not Cancel',
    description: 'modals | do not cancel button text',
  },
  stop: {
    defaultMessage: 'Stop',
    description: 'modals | stop button text',
  },
})
