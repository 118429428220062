import { ReactNode } from 'react'
import ReactDOM from 'react-dom'

import { ModalOverlay, ModalPopup } from '@/lib/core/components/Modal/ModalBase.styles'

export type ModalBaseProps = {
  children: ReactNode
  isActive: boolean
}

export const ModalBase = ({ children, isActive }: ModalBaseProps) =>
  isActive
    ? ReactDOM.createPortal(
        <>
          <ModalPopup role="dialog" aria-modal="true">
            {children}
          </ModalPopup>
          <ModalOverlay />
        </>,
        document.body,
      )
    : null
