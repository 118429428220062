import { defineMessages } from 'react-intl'

export default defineMessages({
  defaultEndPlaceholder: {
    defaultMessage: 'Select End',
    description: 'table date search bar | default start placeholder',
  },
  defaultStartPlaceholder: {
    defaultMessage: 'Select Start',
    description: 'table date search bar | default end placeholder',
  },
})
