import { ReactNode } from 'react'

import { FormattedMessage } from 'react-intl'

import { Button } from '@/lib/core/components/Button'
import { ChevronLeftIcon, CrossIcon } from '@/lib/core/components/Icon'
import { ModalBase, ModalBaseProps } from '@/lib/core/components/Modal'
import {
  Close,
  Container,
  Content,
  Header,
  HeaderActions,
  HeaderContent,
  Subtitle,
  Title,
} from '@/lib/core/components/Modal/FeatureModal.styles'
import messages from '@/lib/core/components/Modal/Modal.messages'

import { FeatureTypes } from '@/lib/utils/features'

export type FeatureModalProps = ModalBaseProps & {
  goBackLabel?: string
  feature: FeatureTypes
  onClose?: (withSuccess?: boolean) => void
  onGoBack?: (() => void) | null
  subtitle?: string | ReactNode
  title: string | ReactNode
  titleIcon?: ReactNode
}

export const FeatureModal = ({
  children,
  goBackLabel,
  feature,
  onClose,
  onGoBack,
  isActive,
  subtitle,
  title,
  titleIcon = <></>,
}: FeatureModalProps) => (
  <ModalBase isActive={isActive}>
    <Container>
      <Header feature={feature}>
        <HeaderContent>
          <Title>
            {titleIcon}
            {title}
          </Title>
          {subtitle && <Subtitle>{subtitle}</Subtitle>}
        </HeaderContent>
        <HeaderActions>
          {onClose && (
            <Close onClick={() => onClose()}>
              <CrossIcon size="32" />
            </Close>
          )}
          {onGoBack && (
            <Button
              type="button"
              fitToContent
              lightVariant
              variant="ghost"
              size="large"
              onClick={onGoBack}
              leftIcon={<ChevronLeftIcon />}
            >
              {goBackLabel || <FormattedMessage {...messages.back} />}
            </Button>
          )}
        </HeaderActions>
      </Header>
      <Content>{children}</Content>
    </Container>
  </ModalBase>
)
