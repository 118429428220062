import { useTheme } from 'styled-components'

import { useIntl } from 'react-intl'

import { SlashIcon } from '@/lib/core/components/Icon'
import { Infobox } from '@/lib/core/components/Infobox'
import { SimpleModal, SimpleModalProps } from '@/lib/core/components/Modal'
import messages from '@/lib/core/components/Modal/Modal.messages'

export type DangerModalProps = SimpleModalProps

export const DangerModal = ({ children, ...props }: DangerModalProps) => {
  const { colors } = useTheme()
  const { formatMessage } = useIntl()

  return (
    <SimpleModal
      cancelLabel={formatMessage(messages.cancel)}
      confirmLabel={formatMessage(messages.confirm)}
      headingIcon={<SlashIcon size="48" color={colors.error} />}
      {...props}
    >
      <Infobox variant="error">{children}</Infobox>
    </SimpleModal>
  )
}
