import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { DateTime } from 'luxon'

import { DateString } from '@/lib/core/interfaces/common'
import { DATE_FORMAT, DATE_FORMAT_API, DATE_TIME_FORMAT } from '@/lib/core/constants/constants'

export const formatDate = (date?: DateString | null, format = DATE_FORMAT) => {
  if (!date) return '-'

  return dayjs(date).format(format)
}

export const formatDateTime = (date?: DateString | null, format = DATE_TIME_FORMAT) => {
  if (!date) return '-'

  return dayjs.utc(date).utcOffset(dayjs().utcOffset()).format(DATE_TIME_FORMAT)
}

export const formatAsDuration = (date?: Date | DateString | null) => {
  if (!date) return '-'

  if (date instanceof Date) {
    return DateTime.fromJSDate(date).toRelative()
  } else {
    return DateTime.fromISO(date).toRelative()
  }
}

export const checkIsDateAndConvertToBackendStandard = (date?: string | null): DateString | null => {
  dayjs.extend(customParseFormat)
  if (!date) return null
  const mDate = dayjs(date, DATE_FORMAT)
  if (mDate.isValid()) {
    return mDate.format(DATE_FORMAT_API)
  }
  return null
}
