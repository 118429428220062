import styled, { DefaultTheme } from 'styled-components'

import { H3 } from '@/lib/core/components/Text'

import { FeatureTypes } from '@/lib/utils/features'

export const Container = styled.div`
  position: relative;
  overflow: auto;
  width: 100%;
  max-width: ${({ theme: { layout } }) => layout.spacing(130)};
  max-height: 100%;
  border-radius: ${({ theme: { layout } }) => layout.borderRadius};
`

export type HeaderProps = {
  feature: FeatureTypes
}

export const Header = styled.header<HeaderProps>`
  --headingsColor: ${({ theme: { colors } }) => colors.textOpposite};
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  gap: ${({ theme: { layout } }) => layout.spacing(2)};
  padding: ${({ theme: { layout } }) => layout.spacing(4)};
  background: ${({ feature, theme }) => theme.colors[feature as keyof DefaultTheme['colors']]};
  color: ${({ theme: { colors } }) => colors.textOpposite};
`

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme: { layout } }) => layout.spacing(1)};
  padding: ${({ theme: { layout } }) => layout.spacing(1)};
`

export const Title = styled(H3)`
  display: flex;
  flex: 1 1 ${({ theme: { layout } }) => layout.spacing(8)};
  align-items: center;
  gap: ${({ theme: { layout } }) => layout.spacing(2)};
  margin: 0;
  font-weight: 700;
`

export const Subtitle = styled.p`
  margin: 0;
  line-height: ${({ theme: { layout } }) => layout.spacing(4)};
`

export const HeaderActions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  align-self: stretch;
`

export const Close = styled.button`
  display: block;
  width: auto;
  height: auto;
  border: none;
  padding: 0;
  background: none;
  color: ${({ theme: { colors } }) => colors.textOpposite};
  cursor: pointer;
`
export const Content = styled.div`
  background: ${({ theme: { colors } }) => colors.backgroundContent};
`
