import styled from 'styled-components'

import { visuallyHidden } from '@/lib/themes/mixins'

export const BASE_CHECKBOX_SIZE = '24px'

export const StyledCheckbox = styled.input`
  ${visuallyHidden};
`

export const StyledLabel = styled.label`
  position: relative;
  display: block;
  min-height: ${BASE_CHECKBOX_SIZE};
  padding: 0 0 0 ${({ theme: { layout } }) => layout.spacing(4)};
  font-size: 1rem;
  line-height: ${BASE_CHECKBOX_SIZE};
  cursor: pointer;

  &:focus {
    outline: ${({ theme: { outline } }) => outline.border};
    outline-offset: ${({ theme: { outline } }) => outline.offset};
  }
`
