import { Infobox } from '@/lib/core/components/Infobox'
import { SimpleModal, SimpleModalProps } from '@/lib/core/components/Modal'

export type InfoModalProps = SimpleModalProps

export const InfoModal = ({ children, ...props }: InfoModalProps) => (
  <SimpleModal {...props}>
    <Infobox>{children}</Infobox>
  </SimpleModal>
)
