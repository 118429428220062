import styled, { css } from 'styled-components'

import { IconButton } from '@/lib/core/components/Button'
import { Input } from '@/lib/core/components/TextField/TextField.styles'

export const Wrapper = styled.div`
  position: relative;
`

export const Trigger = styled(IconButton)`
  position: relative;
  z-index: 10;
  display: block;
`

export type FieldWrapperProps = {
  $isOpen?: boolean
}

export const FieldWrapper = styled.div<FieldWrapperProps>`
  position: absolute;
  top: 50%;
  right: 100%;
  z-index: 9;
  opacity: 0;
  width: 300px;
  margin-right: ${({ theme: { layout } }) => layout.spacing(1)};
  transition:
    opacity 0.25s ease,
    transform 0.2s ease-in-out;
  transform: translate(30%, -50%);
  ${({ $isOpen }) =>
    $isOpen &&
    css`
      opacity: 1;
      transform: translate(0, -50%);
    `}
`

export const SearchInput = styled(Input)`
  width: 400px;
  height: ${({ theme: { layout } }) => layout.spacing(5)};
  border-color: ${({ theme: { colors } }) => colors.borderGray};
  font-size: ${({ theme: { fontSize } }) => fontSize.small};
`
