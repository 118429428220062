import styled from 'styled-components'

export const ModalPopup = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: ${({ theme: { layout } }) => layout.spacing(2)};
`

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  height: 100%;
  background-color: ${({ theme: { modals } }) => modals.overlayColor};
`
