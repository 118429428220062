import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { DateRange, DayPicker, DayPickerProps, DaySelectionMode } from 'react-day-picker'
import 'react-day-picker/dist/style.css'
import { usePopper } from 'react-popper'
import FocusTrap from 'focus-trap-react'
import dayjs from 'dayjs'

import {
  DatePickerCalendarWrapper,
  DatePickerControlsWrapper,
  DatePickerTrigger,
  DatePickerTriggerSeparator,
} from '@/lib/core/components/DatePicker/DatePicker.styles'
import { DATE_FORMAT_API, SHORT_DATE_FORMAT } from '@/lib/core/constants/constants'
import { Nullable } from '@/lib/core/interfaces/common'

type DatePickerDate = Date | Array<Date> | DateRange
type DatePickerApiFormat =
  | string
  | Array<string>
  | {
      from: string | undefined
      to: string | undefined
    }

export type DatePickerProps = DayPickerProps & {
  children?: ReactNode
  clearDateSearch?: () => void
  customTrigger?: boolean
  dateSearchFilter?: Nullable<string | undefined>
  hasError?: boolean
  hasErrorFrom?: boolean
  hasErrorTo?: boolean
  initialDate?: DatePickerApiFormat
  isDateSearchOpen?: boolean
  onChange?: (data: DatePickerDate) => void
  placeholderFrom?: string
  placeholderTo?: string
  resetIconState?: () => void
}

export const parseInitialDate = (date: DatePickerApiFormat): DatePickerDate => {
  if (typeof date === 'string') {
    return dayjs(date).toDate()
  }

  if (Array.isArray(date)) {
    return date.map((item) => dayjs(item).toDate())
  }

  if (date && 'from' in date && 'to' in date) {
    return {
      from: date.from ? new Date(date.from) : undefined,
      to: date.to ? new Date(date.to) : undefined,
    }
  }

  return dayjs().toDate()
}

export const formatDateForApi = (date: DatePickerDate): DatePickerApiFormat => {
  if (date instanceof Date) {
    return dayjs(date).format(DATE_FORMAT_API)
  }

  if (Array.isArray(date)) {
    return date.map((item) => dayjs(item).format(DATE_FORMAT_API))
  }

  if ('from' in date && 'to' in date) {
    return {
      from: dayjs(date.from).format(DATE_FORMAT_API),
      to: dayjs(date.to).format(DATE_FORMAT_API),
    }
  }

  return dayjs().format(DATE_FORMAT_API)
}

export const DatePicker = ({
  children,
  clearDateSearch,
  dateSearchFilter,
  hasError,
  hasErrorFrom,
  hasErrorTo,
  customTrigger,
  initialDate,
  isDateSearchOpen = false,
  onChange,
  placeholderFrom,
  placeholderTo,
  resetIconState,
  ...props
}: DatePickerProps) => {
  const [selected, setSelected] = useState<DatePickerDate | undefined>(
    initialDate ? parseInitialDate(initialDate) : undefined,
  )
  const [isPopperOpen, setIsPopperOpen] = useState(isDateSearchOpen)

  const popperRef = useRef<HTMLDivElement>(null)
  const buttonRef = useRef<HTMLDivElement>(null)
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null)

  const placement = customTrigger ? 'left' : 'bottom-start'

  const popper = usePopper(popperRef.current, popperElement, {
    placement,
  })

  const closePopper = useCallback(() => {
    if (resetIconState) {
      resetIconState()
    }
    setIsPopperOpen(false)
    buttonRef?.current?.focus()
  }, [])

  const clearDatePickerSearch = () => {
    if (clearDateSearch) {
      clearDateSearch()
    }
    setSelected(undefined)
  }

  const handleButtonClick = useCallback(() => {
    setIsPopperOpen(true)
  }, [])
  const { mode } = props
  const isRangeMode = mode === 'range'
  const isMultipleMode = mode === 'multiple'
  const propsByMode = useMemo<Partial<DayPickerProps>>(() => {
    const propsDictionary: { [key in DaySelectionMode]: Partial<DayPickerProps> } = {
      default: {
        mode: 'default',
      },
      multiple: {
        mode: 'multiple',
        onSelect: (dates: Array<Date> | undefined) => {
          const sortedDates = dates
            ? [...dates].sort((curr, next) => dayjs(curr).unix() - dayjs(next).unix())
            : []

          setSelected(sortedDates)

          if (onChange) {
            onChange(sortedDates)
          }
        },
      },
      range: {
        mode: 'range',
        numberOfMonths: 2,
        onSelect: (range: DateRange | undefined) => {
          setSelected(range)

          if (onChange) {
            onChange(range!)
          }
        },
      },
      single: {
        mode: 'single',
        onSelect: (date: Date | undefined) => {
          setSelected(date)

          if (onChange) {
            onChange(date!)
          }
        },
      },
    }

    return propsDictionary[mode || 'single']
  }, [mode])
  const displayValue = useMemo<{
    from: Date | undefined
    to: Date | undefined
  }>(() => {
    switch (mode) {
      case 'single': {
        const data = selected as Date

        return {
          from: data,
          to: undefined,
        }
      }
      case 'range': {
        const data = selected as DateRange

        return {
          from: data?.from,
          to: data?.to,
        }
      }
      case 'multiple': {
        const data = selected as Array<Date>

        return {
          from: data ? data[0] : undefined,
          to: data ? data[data.length - 1] : undefined,
        }
      }
      case 'default':
      default:
        return {
          from: undefined,
          to: undefined,
        }
    }
  }, [mode, selected])

  useEffect(() => {
    setIsPopperOpen(isDateSearchOpen)
  }, [isDateSearchOpen])

  useEffect(() => {
    if (dateSearchFilter === undefined) {
      setSelected(undefined)
    }
  }, [dateSearchFilter])

  return (
    <>
      {customTrigger ? (
        <div ref={popperRef} />
      ) : (
        <DatePickerControlsWrapper ref={popperRef}>
          <DatePickerTrigger
            $hasError={hasError || hasErrorFrom}
            $hasValue={!!displayValue.from}
            onClick={handleButtonClick}
            type="button"
          >
            {displayValue.from
              ? dayjs(displayValue.from).format(SHORT_DATE_FORMAT)
              : placeholderFrom}
          </DatePickerTrigger>
          {(isRangeMode || isMultipleMode) && (
            <>
              <DatePickerTriggerSeparator>TO</DatePickerTriggerSeparator>
              <DatePickerTrigger
                $hasError={hasError || hasErrorTo}
                $hasValue={!!displayValue.to}
                onClick={handleButtonClick}
                type="button"
              >
                {displayValue.to ? dayjs(displayValue.to).format(SHORT_DATE_FORMAT) : placeholderTo}
              </DatePickerTrigger>
            </>
          )}
        </DatePickerControlsWrapper>
      )}
      {isPopperOpen && (
        <FocusTrap
          active
          focusTrapOptions={{
            allowOutsideClick: true,
            clickOutsideDeactivates: true,
            initialFocus: false,
            onDeactivate: closePopper,
          }}
        >
          <DatePickerCalendarWrapper
            tabIndex={-1}
            style={popper.styles.popper}
            {...popper.attributes.popper}
            ref={setPopperElement}
            role="dialog"
          >
            {/* @ts-expect-error Day picker has dynamic typing and typescript gets confused with the props type because of it */}
            <DayPicker
              defaultMonth={displayValue.from}
              selected={selected}
              weekStartsOn={1}
              formatters={{
                formatWeekdayName: (day, options) =>
                  options?.locale?.localize?.day(dayjs(day).day())[0] || undefined,
              }}
              {...props}
              {...propsByMode}
            />
            {/*{clearDatePickerSearch && (*/}
            {/*  <Button variant="text" onClick={clearDatePickerSearch}>*/}
            {/*    <FormattedMessage {...messages.clear} />*/}
            {/*  </Button>*/}
            {/*)}*/}
          </DatePickerCalendarWrapper>
        </FocusTrap>
      )}
    </>
  )
}
