import { Controller, useFormContext } from 'react-hook-form'
import get from 'lodash-es/get'

import {
  DatePicker,
  DatePickerProps,
  formatDateForApi,
} from '@/lib/core/components/DatePicker/DatePicker'
import {
  FieldError,
  FieldErrorMessage,
  InputWrapper,
  Label,
} from '@/lib/core/components/TextField/TextField.styles'

export type DatePickerFieldProps = DatePickerProps & {
  name: string
  label?: string
  required?: boolean
  displayInline?: boolean
}

export const DatePickerField = ({ name, label, displayInline, ...props }: DatePickerFieldProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext()
  const error= get(errors, `${name}.message`)

  return (
    <>
      <Label
        $required={!!label && props.required}
        $displayInline={displayInline}
        $disabled={!!props.disabled}
        htmlFor={name}
      >
        {label}
      </Label>
      <InputWrapper $disabled={!!props.disabled}>
        <Controller
          control={control}
          name={name}
          render={({ field }) => (
            <DatePicker
              onChange={(data) => field.onChange(formatDateForApi(data))}
              initialDate={field.value}
              {...props}
            />
          )}
        />
      </InputWrapper>
      <FieldError>{error && <FieldErrorMessage>{error?.message?.toString()}</FieldErrorMessage>}</FieldError>
    </>
  )
}
