import { useEffect, useState } from 'react'
import { useTheme } from 'styled-components'

import { useIntl } from 'react-intl'

import { DatePicker } from '@/lib/core/components/DatePicker'
import { CalendarIcon, CloseIcon } from '@/lib/core/components/Icon'
import { DATE_FORMAT_API } from '@/lib/core/constants/constants'

import { formatDate } from '@/lib/utils/date-time'

import { Trigger, Wrapper } from './TableDateSearch.styles'
import messages from './TableDateSearch.messages'

export type TableDateSearchProps = {
  clearDateSearch?: () => void
  dateSearchFilter?: string | null
  onDateSearch: (from: string, to: string) => void
}

export type DatesState = {
  from: Date | undefined
  to: Date | undefined
}

export const TableDateSearch = ({
  clearDateSearch,
  dateSearchFilter,
  onDateSearch,
}: TableDateSearchProps) => {
  const { colors } = useTheme()
  const { formatMessage } = useIntl()
  const [isDateSearchOpen, setIsDateSearchOpen] = useState(!!dateSearchFilter)
  const [dates, setDates] = useState<DatesState>({
    from: undefined,
    to: undefined,
  })

  const onChange = (newDates: DatesState | undefined) => {
    setDates({
      from: newDates?.from,
      to: newDates?.to,
    })

    if (newDates?.to) {
      onDateSearch(
        formatDate(newDates?.from?.toISOString(), DATE_FORMAT_API),
        formatDate(newDates?.to?.toISOString(), DATE_FORMAT_API),
      )
    }
  }

  useEffect(() => {
    if (dateSearchFilter === undefined) {
      setDates({
        from: undefined,
        to: undefined,
      })
    }
  }, [dateSearchFilter])

  return (
    <Wrapper>
      <Trigger type="button" onClick={() => setIsDateSearchOpen(!isDateSearchOpen)}>
        {isDateSearchOpen ? (
          <CloseIcon size="24" />
        ) : (
          <CalendarIcon color={dates.to !== undefined ? colors.active : 'currentColor'} size="24" />
        )}
      </Trigger>
      <DatePicker
        clearDateSearch={clearDateSearch}
        customTrigger
        dateSearchFilter={dateSearchFilter}
        isDateSearchOpen={isDateSearchOpen}
        mode="range"
        onChange={(newDates) => onChange(newDates as DatesState)}
        placeholderFrom={formatMessage(messages.defaultStartPlaceholder)}
        placeholderTo={formatMessage(messages.defaultEndPlaceholder)}
        resetIconState={() => setIsDateSearchOpen(false)}
      />
    </Wrapper>
  )
}
