import { css } from 'styled-components'

export const visuallyHidden = css`
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  opacity: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
`

export const flexCenter = css`
  display: flex;
  justify-content: center;
  align-items: center;
`
