import { useEffect, useRef, useState } from 'react'
import { debounce } from 'lodash-es'
import { useTheme } from 'styled-components'

import { useIntl } from 'react-intl'

import { CloseIcon, SearchIcon } from '@/lib/core/components/Icon'

import { FieldWrapper, SearchInput, Trigger, Wrapper } from './TableSearch.styles'
import messages from './TableSearch.messages'

export type TableSearchProps = {
  debounceTime?: number
  defaultValue?: string | null
  inputName?: string
  onSearch: (term?: string) => void
  placeholder?: string
}

export const TableSearch = ({
  debounceTime = 300,
  defaultValue,
  inputName = 'table-search',
  onSearch,
  placeholder,
}: TableSearchProps) => {
  const { colors } = useTheme()
  const { formatMessage } = useIntl()
  const [isOpen, setIsOpen] = useState(!!defaultValue)
  const inputRef = useRef<HTMLInputElement>(null)
  const onKeyUp = debounce(() => onSearch(inputRef.current?.value), debounceTime)

  const onTransitionEnd = () => {
    if (isOpen) {
      inputRef.current?.focus()
    }
  }

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = defaultValue || ''
    }
  }, [defaultValue])

  return (
    <Wrapper>
      <Trigger type="button" onClick={() => setIsOpen(!isOpen)}>
        {isOpen ? (
          <CloseIcon size="24" />
        ) : (
          <SearchIcon color={inputRef.current?.value ? colors.active : 'currentColor'} size="24" />
        )}
      </Trigger>
      <FieldWrapper $isOpen={isOpen} onTransitionEnd={onTransitionEnd}>
        <SearchInput
          name={inputName}
          onKeyUp={onKeyUp}
          placeholder={placeholder || formatMessage(messages.defaultPlaceholder)}
          ref={inputRef}
        />
      </FieldWrapper>
    </Wrapper>
  )
}
