import { ReactNode, useEffect, useState } from 'react'
import { useTheme } from 'styled-components'

import { FormattedMessage } from 'react-intl'

import { Loader } from '@/lib/core/components/Loader'
import { Checkmark } from '@/lib/core/components/Checkmark'
import {
  CheckmarkTh,
  CounterWrapper,
  FullWidthCell,
  HeaderActions,
  LoadingWrapper,
  StartRow,
  StickyLoader,
  StyledTable,
  TableContent,
  TableHeader,
  TableHeaderTitle,
  Th,
  Tr,
  Wrapper,
} from '@/lib/core/components/Table/Table.styles'
import { TableSearch } from '@/lib/core/components/Table/components'
import messages from '@/lib/core/components/Table/Table.messages'
import { Button } from '@/lib/core/components/Button'
import { SortDirection, SortInfo } from '@/lib/core/interfaces/common'

import { TableDateSearch } from './components/TableDateSearch'

export type TableColumns = Array<{
  label: string
  accessor: string
  sortable?: boolean
  sortKey?: string
  width?: string
  tooltip?: ReactNode
  textAlign?: 'start' | 'end' | 'left' | 'right' | 'center' | 'justify' | 'match-parent'
}>
export const sortRotation: { [key: string]: SortDirection | undefined } = {
  [SortDirection.ASC]: SortDirection.DESC,
  [SortDirection.DESC]: undefined,
  undefined: SortDirection.ASC,
}

export type TableProps = {
  checkAll?: (allChecked: boolean) => void
  children: Array<ReactNode>
  clearDateSearch?: () => void | undefined
  clearSearch?: () => void | undefined
  columns: TableColumns
  customNoResultsText?: string
  dateSearchFilter?: string | null
  elementsCounter?: number
  externalAllChecked?: boolean
  header?: ReactNode
  hideHeader?: boolean
  isLoading?: boolean
  isSelectable?: boolean
  onDateSearch?: (from: string, to: string) => void
  onSearch?: (term?: string) => void
  searchFilter?: string | null
  stickyLoader?: boolean
  onSort?: (accessor: string, direction: SortDirection | undefined) => void
  sortInfo?: SortInfo
  renderCustomFilter?: () => ReactNode
}

export const Table = ({
  checkAll,
  children,
  clearDateSearch,
  clearSearch,
  columns,
  customNoResultsText,
  dateSearchFilter,
  elementsCounter,
  externalAllChecked,
  header,
  hideHeader,
  isLoading,
  isSelectable,
  onDateSearch,
  onSearch,
  searchFilter,
  onSort,
  sortInfo = {},
  stickyLoader = true,
  renderCustomFilter,
}: TableProps) => {
  const { colors } = useTheme()
  const [isAllChecked, setIsAllChecked] = useState<boolean>(false)
  const hasActions = !!onSearch

  const onCheckAll = () => {
    const newValue = !isAllChecked

    setIsAllChecked(newValue)

    if (checkAll) checkAll(newValue)
  }

  useEffect(() => {
    setIsAllChecked(!!externalAllChecked)
  }, [externalAllChecked])

  return (
    <>
      {stickyLoader && isLoading && (
        <StickyLoader>
          <Loader color={colors.backgroundTab} />
        </StickyLoader>
      )}
      <Wrapper>
        {!hideHeader && (
          <TableHeader>
            {(header && <TableHeaderTitle>{header} </TableHeaderTitle>) ||
              (renderCustomFilter?.()) ||
              (typeof elementsCounter === 'number' && (
                <CounterWrapper>
                  <FormattedMessage
                    {...messages.allCounter}
                    values={{
                      count: elementsCounter,
                    }}
                  />
                </CounterWrapper>
              ))}
            {hasActions && (
              <HeaderActions>
                {onSearch && <TableSearch defaultValue={searchFilter} onSearch={onSearch} />}
                {onDateSearch && (
                  <TableDateSearch
                    clearDateSearch={clearDateSearch}
                    dateSearchFilter={dateSearchFilter}
                    onDateSearch={onDateSearch}
                  />
                )}
                {clearSearch && (
                  <Button variant="text" onClick={() => clearSearch()}>
                    <FormattedMessage {...messages.clearFilters} />
                  </Button>
                )}
              </HeaderActions>
            )}
          </TableHeader>
        )}
        <TableContent>
          {isLoading && (
            <LoadingWrapper>
              {!stickyLoader && <Loader color={colors.backgroundTab} />}
            </LoadingWrapper>
          )}
          <StyledTable $isLoading={isLoading} $isSelectable={isSelectable} cellSpacing="0">
            <thead>
              <tr>
                {isSelectable && (
                  <CheckmarkTh>
                    <Checkmark
                      isActive={
                        typeof externalAllChecked === 'boolean' ? externalAllChecked : isAllChecked
                      }
                      onClick={onCheckAll}
                    />
                  </CheckmarkTh>
                )}
                {columns.map(
                  ({ accessor, sortKey, label, width, textAlign, sortable, tooltip }) => (
                    <Th
                      key={`${accessor}-${label}`}
                      // For some reason typescript is being a little inconsistent with whether this is an error ot not
                      // eslint-disable-next-line
                      // @ts-ignore Typing error in styled components it seems
                      css={{
                        textAlign,
                        width,
                        cursor: sortable ? 'pointer' : 'auto',
                      }}
                      onClick={() =>
                        sortable &&
                        onSort &&
                        onSort(
                          sortKey ?? accessor,
                          sortRotation[`${sortInfo[sortKey ?? accessor]}`],
                        )
                      }
                    >
                      <StartRow>
                        {label}

                        {tooltip}

                        {!sortable
                          ? null
                          : {
                              [SortDirection.ASC]: (
                                <span className="material-symbols-outlined">expand_more</span>
                              ),
                              [SortDirection.DESC]: (
                                <span className="material-symbols-outlined">expand_less</span>
                              ),
                              undefined: (
                                <span className="material-symbols-outlined">sort_by_alpha</span>
                              ),
                            }[sortInfo[sortKey ?? accessor] as string]}
                      </StartRow>
                    </Th>
                  ),
                )}
              </tr>
            </thead>
            <tbody>
              {children.length > 0 ? (
                children
              ) : (
                <Tr>
                  <FullWidthCell colSpan={columns.length + (isSelectable ? 1 : 0)}>
                    {customNoResultsText ?? <FormattedMessage {...messages.noResults} />}
                  </FullWidthCell>
                </Tr>
              )}
            </tbody>
          </StyledTable>
        </TableContent>
      </Wrapper>
    </>
  )
}
