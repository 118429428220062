import { defineMessages } from 'react-intl'

export default defineMessages({
  allCounter: {
    defaultMessage: 'All ({count})',
    description: 'Table | A total number of records displayed above the table.',
  },
  clearFilters: {
    defaultMessage: 'Clear filters',
    description: 'Table | clear filter button label.',
  },
  noResults: {
    defaultMessage: 'No results.',
    description: 'Table | no results text when there is no records',
  },
})
