import { ReactNode } from 'react'
import { useTheme } from 'styled-components'

import { FormattedMessage } from 'react-intl'

import { Button, ButtonProps } from '@/lib/core/components/Button'
import { InfoIcon } from '@/lib/core/components/Icon'
import { H3 } from '@/lib/core/components/Text'
import { Modal, ModalProps } from '@/lib/core/components/Modal'
import messages from '@/lib/core/components/Modal/Modal.messages'

export type SimpleModalProps = ModalProps & {
  cancelLabel?: string
  cancelProps?: Partial<ButtonProps>
  confirmLabel?: string
  confirmProps?: Partial<ButtonProps>
  headingIcon?: ReactNode
  isActive: boolean
  onCancel?: () => void
  onConfirm?: () => void
  title: string | ReactNode
}

export const SimpleModal = ({
  actions,
  cancelLabel,
  cancelProps,
  children,
  confirmLabel,
  confirmProps,
  heading,
  headingIcon,
  onCancel,
  onConfirm,
  title,
  ...props
}: SimpleModalProps) => {
  const { colors } = useTheme()

  return (
    <Modal
      actions={
        actions || (
          <>
            {onCancel && (
              <Button
                minWidth
                fitToContent
                onClick={onCancel}
                variant="ghost"
                size="large"
                {...cancelProps}
              >
                {cancelLabel || <FormattedMessage {...messages.close} />}
              </Button>
            )}
            {onConfirm && (
              <Button
                minWidth
                fitToContent
                onClick={onConfirm}
                variant="secondary"
                size="large"
                {...confirmProps}
              >
                {confirmLabel || <FormattedMessage {...messages.continue} />}
              </Button>
            )}
          </>
        )
      }
      heading={
        heading || (
          <>
            {headingIcon || <InfoIcon size="48" color={colors.active} />}
            <H3>{title}</H3>
          </>
        )
      }
      {...props}
    >
      {children}
    </Modal>
  )
}
