import { useTheme } from 'styled-components'

import { MergeElementProps } from '@/lib/core/interfaces/common'
import { CheckmarkIcon } from '@/lib/core/components/Icon'

import { StyledCheckmark } from './Checkmark.styles'

type CheckmarkProps = {
  isActive: boolean
}

export type CheckmakrElementProps = MergeElementProps<'span', CheckmarkProps>

export const CheckmarkComponent = ({ ...props }: CheckmakrElementProps) => {
  const { colors } = useTheme()

  return (
    <StyledCheckmark {...props}>
      <CheckmarkIcon color={props.color || colors.active} />
    </StyledCheckmark>
  )
}
