import styled, { css } from 'styled-components'

import { StyledCheckmark } from '@/lib/core/components/Checkmark/Checkmark.styles'
import { LoaderWrapper } from '@/lib/core/components/Loader/Loader.styles'

const tableHeaderHeight = 64
const tableHeadingHeight = 70
const loaderOffset = 11

export const Wrapper = styled.div`
  overflow: hidden;
  flex: 1;
  background-color: #fff;
  font-size: ${({ theme: { fontSize } }) => fontSize.small};
`

export const TableHeader = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme: { layout } }) => layout.spacing(1)};
  min-height: ${({ theme: { layout } }) => layout.spacing(8)};
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.borderGray};
  padding: ${({ theme: { layout } }) => layout.spacing(2)};
`

export const TableHeaderTitle = styled.span`
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  padding: 9px 15px;
`

export const Actions = styled.div`
  display: flex;
  margin-left: 8px;
  align-items: center;
  gap: ${({ theme: { layout } }) => layout.spacing(2)};
`

export const HeaderActions = styled(Actions)`
  gap: ${({ theme: { layout } }) => layout.spacing(1)};
`

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme: { layout } }) => layout.spacing(1)};
`

export const StartRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: ${({ theme: { layout } }) => layout.spacing(1)};
`

export const EmptyAction = styled.div`
  display: flex;
  min-width: ${({ theme: { layout } }) => layout.spacing(3)};
`

export const CounterWrapper = styled.p`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
`

export const TableContent = styled.div`
  position: relative;
`

export type StyledTableProps = {
  $isLoading?: boolean
  $isSelectable?: boolean
}

export const StyledTable = styled.table<StyledTableProps>`
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  ${({ $isLoading }) =>
    $isLoading &&
    css`
      filter: blur(2px);
    `}

  th,
  td {
    padding: ${({ theme: { layout } }) => layout.spacing(1)};

    &:first-child {
      padding-left: ${({ theme: { layout } }) => layout.spacing(2)};
    }

    &:last-child {
      padding-right: ${({ theme: { layout } }) => layout.spacing(2)};
    }
  }
`

export const Tbody = styled.tbody``

export const Tr = styled.tr<{
  $background?: string
  $border?: string
}>`
  border-top: ${({ $border = 'none' }) => $border};
  &:nth-child(odd) {
    background: ${({ $background = '#EFF5F7' }) => $background};
  }
`

export const Th = styled.th`
  position: relative;
  height: 70px;
  font-weight: 600;
  text-align: left;

  ${StyledCheckmark} {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

export const CheckmarkTh = styled(Th)`
  width: ${({ theme: { layout } }) => layout.spacing(8)};
`

export const Td = styled.td<{
  $align?: 'left' | 'center' | 'right'
  $height?: string
}>`
  position: relative;
  height: ${({ $height = '70px' }) => $height};
  text-align: ${({ $align = 'left' }) => $align};

  ${StyledCheckmark} {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

export const CheckmarkTd = styled(Td)`
  width: ${({ theme: { layout } }) => layout.spacing(5)};
`

export const FullWidthCell = styled(Td)`
  text-align: center;
`

export const Owner = styled.p`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: ${({ theme: { layout } }) => layout.spacing(2)};
  margin: 0;
`

export const LoadingWrapper = styled.div`
  position: absolute;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);

  & > * {
    filter: drop-shadow(0 0 10px rgb(0 0 0 / 0.4));
    width: 48px;
    height: 48px;
  }
`

export const StickyLoader = styled.div`
  position: sticky;
  top: 0;
  left: 50%;
  z-index: 2;
  width: 100%;
  height: 0;
  transform: translateY(${tableHeaderHeight + tableHeadingHeight + loaderOffset}px);

  ${LoaderWrapper} {
    position: absolute;
    top: 0;
    left: 50%;
    filter: drop-shadow(0 0 10px rgb(0 0 0 / 0.4));
    width: 48px;
    height: 48px;
    margin-left: -24px;
  }
`

export const HeaderSpan = styled.span`
  display: flex;
  gap: 0.5rem;
`

export const ExpiresSoonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`

export const ExpiresSoon = styled.div`
  min-width: fit-content;
  display: flex;
  height: 24px;
  align-items: center;
  width: 63px;
  margin-right: 10px;
  background-color: white;
  border: 1px solid #91979d;
  border-radius: 5px;
  padding: 4px 8px 4px 8px;
  font-weight: 600;
  color: #91979d;
  font-size: 8px;
  line-height: 8px;
`
