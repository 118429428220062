import { ReactNode } from 'react'

import { ModalBase } from '@/lib/core/components/Modal'

import { Container, Content, Footer, Heading } from './Modal.styles'

export type ModalProps = {
  actions?: ReactNode
  actionsAlignItems?: string
  actionsJustifyContent?: string
  children?: ReactNode
  heading?: ReactNode
  isActive: boolean
}

export const Modal = ({
  actions,
  actionsAlignItems = 'flex-end',
  actionsJustifyContent = 'center',
  children,
  heading,
  isActive,
}: ModalProps) => (
  <ModalBase isActive={isActive}>
    <Container>
      {heading && <Heading>{heading}</Heading>}
      <Content>{children}</Content>
      {actions && (
        <Footer
          $actionsAlignItems={actionsAlignItems}
          $actionsJustifyContent={actionsJustifyContent}
        >
          {actions}
        </Footer>
      )}
    </Container>
  </ModalBase>
)
